<template>
    <main>
        <loading
            :active="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
        />
        
        <template v-if="store.state.dataToken.typeUser=='SHOP'">
            <HeadShop />
        </template>
        <template v-else>
            <UserHeader />
        </template>
        
        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb">
                    <a href="/">
                        <span class="icon-home-outline"></span>
                    </a> /
                    <span>Mi Cuenta</span>                    
                </p>
                <h2>
                    <a href="#" @click="router.go(-1)">
                        <img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington" />
                    </a> Mi Cuenta
                </h2>
                <!-- <p class="form__success" v-if="formUserAccountUpdate">Sus datos fueron actualizados</p>                 -->
                <p
                    class="form__success"
                    v-if="formChangePasswordSuccessful"
                >Su contraseña fué cambiada</p>
                <div class="page-content">
                    <div class="color-loadpoint">                        
                        <p class="form__success">
                            El envío de tu solicitud de registro de puntos se completó. El administrador confirmará el estado por correo electrónico 
                            o SMS, segun se haya registrado en Club Barrington. Puede consultar el estado de su solicitud tambien en el "Registro histórico de puntos".
                        </p>                        
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
import UserHeader from "@/views/private/user/components/UserHeader";
import HeadShop from "@/views/private/shop/components/HeadShop";
import store from "@/store";
export default {
    name: "RegisterPointSuccess",
    components: {
        UserHeader,
        HeadShop,
    },
    props: {
        data: Object
    },
    setup() {

        return {
            store
        }
    }
};
</script>